@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply text-black;
        @apply border-black;
        @apply font-calluna;
        @apply overflow-y-scroll;
        @apply lining-nums;
        font-size: 20px;
        line-height: 30px;
    }

    body {
        @apply bg-grey-100;
    }

    main {
        @apply flex flex-col gap-10 md:gap-16 mb-40 items-stretch;
    }

    section,
    aside {
        @apply flex flex-col gap-4;
    }

    input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        font: inherit;
        @apply text-black;
        width: 0.9rem;
        height: 0.9rem;
        @apply border-2;
        @apply border-black;
        transform: translateY(0.1rem);
    }

    input[type='checkbox']:checked {
        background-image: url('images/tick.svg');
    }

    h1 {
        @apply border-b-2;
        @apply pb-3 md:pb-4 md:pt-4;
        @apply text-xl md:text-4xl;
        @apply leading-tight;
        @apply tracking-tight;
        @apply font-trajan;
        @apply font-semibold;
        @apply uppercase;
        @apply lining-nums;
    }

    h2 {
        @apply border-b-2;
        @apply pb-3 md:pb-2 md:pt-2;
        @apply text-xl md:text-2xl;
        @apply leading-tight;
        @apply tracking-tight;
        @apply font-trajan;
        @apply font-semibold md:font-normal;
        @apply uppercase;
        @apply lining-nums;
    }

    h3 {
        @apply border-b-2;
        @apply pb-0 md:pb-3;
        @apply text-base md:text-xl;
        @apply font-bold md:font-semibold;
        @apply leading-tight;
        @apply tracking-tight;
        @apply font-trajan;
        @apply uppercase;
        @apply lining-nums;
        @apply text-teal;
    }

    h4 {
        @apply text-base;
        @apply leading-tight;
        @apply tracking-tight;
        @apply font-trajan;
        @apply font-bold;
        @apply uppercase;
        @apply lining-nums;
        @apply text-teal;
    }

    p,
    li {
        @apply text-xs leading-normal md:text-base;
    }
}
